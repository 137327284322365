html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-family: 'Gotham', 'Avenir Next', 'Lato', 'Arial', sans-serif;
  cursor: default;
}

h1 {
  font-style: normal;
  font-weight: normal !important;
  font-size: 48px !important;
  line-height: 64px;
  letter-spacing: -0.02em;
}

h2 {
  font-style: normal;
  font-weight: normal !important;
  font-size: 32px !important;
  line-height: 40px;
  letter-spacing: -0.02em;
}

h3 {
  font-style: normal;
  font-weight: normal !important;
  font-size: 24px !important;
  line-height: 32px;
  letter-spacing: -0.02em;
}

small {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.4;
}

#chat-layout {
  background: radial-gradient(circle at center, #cad5da, #edf7fb);
}
.dark #chat-layout {
  background: radial-gradient(circle at center, #0083a3, #162839);
}

.chat-start .chat-bubble:before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 3 3 L 3 0 C 3 1 1 3 0 3'/%3e%3c/svg%3e");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

.chat-end .chat-bubble:before {
  -webkit-mask-image: url("data:image/svg+xml,%3csvg width='3' height='3' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='black' d='m 0 3 L 1 3 L 3 3 C 2 3 0 1 0 0'/%3e%3c/svg%3e");
  -webkit-mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
}

.scroll-menu-arrow--disabled {
  visibility: hidden !important;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.01);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(12, 192, 223, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(12, 192, 223, 0.55);
  border-radius: 10px;
}

@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.eot');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.woff2')
      format('woff2'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.woff')
      format('woff'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Book.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.eot');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.woff2')
      format('woff2'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.woff')
      format('woff'),
    url('https://cdn.cyberescape.livingsecurity.com/fonts/Gotham-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'font-awesome';
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-brands-400.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-duotone-900.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-light-300.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-regular-400.ttf');
  src: url('https://cdn.cyberescape.livingsecurity.com/fonts/fa/fa-solid-900.ttf');
}
